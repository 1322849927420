import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"

import BlogPostTemplate from "./blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "../pages/index"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const PdfMarketingTemplate = ({ data, location }) => {
  const document = data.prismicPdfMarketingPages

  const {
    data: {
      seo_title,
      seo_short_description,
      seo_description,
      image_link_preview,
      pdf_file,
      utm_parameters,
    },
  } = document

  useEffect(() => {
    const searchQuery = location.search

    // Define the allowed search queries based on UTM parameters
    const allowedSearchQueries = utm_parameters.map(param => param.utm_value)

    // Check if the current search query is in the allowed search queries
    if (!allowedSearchQueries.includes(searchQuery)) {
      // If not, redirect to the first UTM parameter or a default one
      navigate(
        `${location.pathname}${
          allowedSearchQueries[0] || "?utm_source=default&utm_medium=default"
        }`
      )
    }
  }, [location.search, utm_parameters])

  return (
    <>
      <SEO
        image={image_link_preview?.url}
        location={location}
        title={seo_title?.text || "PDF Marketing"}
        shortDesc={seo_short_description?.text || ""}
        description={seo_description?.text || ""}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <div style={{ marginTop: "10px" }}>
            <iframe
              src={pdf_file?.url}
              width="100%"
              height="1000"
              title={seo_title?.text || "PDF Marketing"}
              description={seo_description?.text || ""}
            />
          </div>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(PdfMarketingTemplate, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})

export const pageQuery = graphql`
  query PdfMarketingBySlug($slug: String!) {
    prismicPdfMarketingPages(uid: { eq: $slug }) {
      uid
      data {
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          url
        }
        pdf_file {
          url
        }
        utm_parameters {
          utm_value
        }
      }
    }
  }
`
